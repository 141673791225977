import AnimateText from '../../../utilities/AnimateText';
import './FrontNothing.scss'
import Marquee from "react-fast-marquee";
import { Squircle } from "@squircle-js/react";

function FrontNothing() {

   const list = [
      { title: 'без добавления растительных масел' },
      { title: 'без консервантов и искусственных пищевых красителей' },
      { title: 'минимальное количество компонентов' },
      { title: 'без добавления растительных масел' },
      { title: 'без консервантов и искусственных пищевых красителей' },
      { title: 'минимальное количество компонентов' },
      { title: 'без добавления растительных масел' },
      { title: 'без консервантов и искусственных пищевых красителей' },
      { title: 'минимальное количество компонентов' },
      { title: 'без добавления растительных масел' },
      { title: 'без консервантов и искусственных пищевых красителей' },
      { title: 'минимальное количество компонентов' },

   ]
   return (
      <section className='front_nothing'>
         <div className="container">
            <div className="front_nothing__head">
               <AnimateText
                  containerClass={'front_nothing__head'}
                  textClass={'.front_nothing__head h2 b'}
                  trigger={'.front_nothing__head'}
                  colorStart={'transparent'}
                  colorEnd={'#FFF'}
               >
                  <h2>Ничего <b>лишнего</b></h2>
                  <div className="text">
                     <p>
                        Наши конфеты не&nbsp;хранятся вечно, как большинство продуктов
                        из&nbsp;супермаркета. Их&nbsp;надо есть быстро, не&nbsp;откладывая удовольствие на&nbsp;завтра.
                     </p>
                  </div>
               </AnimateText>

            </div>
            <div className="nothing_wrap">
               <Marquee speed={'60'}>
                  <div className="nothing_wrap_list">
                     {list.map((item, index) => (
                        <Squircle
                           cornerRadius={20}
                           cornerSmoothing={1}
                           className='item'
                           key={index}
                        >
                           {item.title}
                        </Squircle>
                     ))}
                  </div>
               </Marquee>
            </div>
         </div>
      </section>
   );
}

export default FrontNothing;


