import './Footer.scss'

function Footer() {
   return (
      <footer className='footer' data-heart="true" id='contacts'>
         <div className="container">
            <div className="footer__wrap row">
               <div className="col-xl-7 col-md-9">
                  <div className="left">
                     <a href="https://drugproduct.ru/" className="logo" target='_blank' rel="noopener noreferrer" title='Сайт Другой Продукт'>
                        <svg width="261" height="60" viewBox="0 0 261 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M20.244 52.5371H0V59.2851H20.244V52.5371Z" fill="#29D300" />
                           <path d="M2.24805 9.23828V9.32676V50.8509H9.29123V16.4637H10.9502V50.8509H17.9934V9.23828H2.24805Z" fill="#29D300" />
                           <g clipPath="url(#clip0_117_671)">
                              <path d="M92.7758 16.7885H96.9637V9.74414H92.7758H85.9336V16.7885V59.1728H92.7758V16.7885Z" fill="white" />
                              <path d="M120.086 43.9608L122.327 9.80469H115.131V59.1742H123.33L126.043 23.0646L127.695 23.183L124.981 59.1742H132.354V9.80469H123.979L121.737 44.0792L120.086 43.9608Z" fill="white" />
                              <path d="M57.0316 50.8267C59.391 50.8267 62.0453 50.7083 63.8148 48.3996C65.5844 46.1502 65.3484 41.7105 65.3484 39.9346V20.3999C65.3484 17.6769 65.5254 14.5987 63.8148 11.994C62.1043 9.38942 59.45 9.56701 57.0316 9.56701H50.1895V59.0549H57.0316V50.8267ZM57.0316 16.6113H58.6832V44.1375H57.0316V16.6113Z" fill="white" />
                              <path d="M76.2023 50.2952V51.9527H69.0062V58.9971H81.393L84.1652 9.56836H76.2023V44.1389H74.5508V9.56836H66.5879L69.0062 50.2952H76.2023Z" fill="white" />
                              <path d="M48.7738 58.9971V51.9527H47.1222V9.56836H38.6285H31.7863V51.8935H30.1348V58.9971H48.7738ZM38.6285 16.6127H40.2801V51.9527H38.6285V16.6127Z" fill="white" />
                              <path d="M113.422 34.4896V21.6441C113.422 19.217 113.598 15.4877 112.419 13.179C111.416 11.1664 109.293 9.21289 105.754 9.21289C102.214 9.21289 100.091 11.1664 99.0883 13.179C98.0856 15.1917 98.0856 19.217 98.0856 21.6441V34.5488V47.3943C98.0856 49.8214 97.9086 53.5507 99.0883 55.8594C100.091 57.8721 102.214 59.8255 105.754 59.8255C109.293 59.8255 111.416 57.8721 112.419 55.8594C113.598 53.5507 113.422 49.8214 113.422 47.3943V34.4896ZM106.579 34.4896V52.1892H105.754H104.928V34.4896V16.79H105.754H106.579V34.4896Z" fill="white" />
                              <path d="M260.999 9.56836H245.84V16.6127H250.028V58.9971H256.87V16.6127H260.999V9.56836Z" fill="white" />
                              <path d="M155.064 9.56836H139.905H139.846V58.9971H146.688V16.6127H148.339V58.9971H155.182V9.56836H155.064Z" fill="white" />
                              <path d="M236.404 9.50781V30.7592H234.752V9.56701H227.91V58.9957H234.752V37.8035H236.404V58.9957H244.662L242.066 34.2518L244.662 9.50781H236.404Z" fill="white" />
                              <path d="M163.852 50.8267C166.211 50.8267 168.866 50.7083 170.635 48.3996C172.405 46.1502 172.169 41.7105 172.169 39.9346V20.3999C172.169 17.6769 172.346 14.5987 170.635 11.994C168.925 9.38942 166.27 9.56701 163.852 9.56701H157.01V58.9957H163.852V50.7675V50.8267ZM163.852 16.6113H165.504V44.1375H163.852V16.5521V16.6113Z" fill="white" />
                              <path d="M218.709 50.2952V51.9527H211.513V58.9971H223.84L226.671 9.56836H218.709V44.0797H217.057V9.56836H209.035L211.513 50.2952H218.709Z" fill="white" />
                              <path d="M209.034 58.9971V51.9527H207.382V9.56836H198.888H192.046V51.8935H190.395V58.9971H209.034ZM198.888 16.6127H200.54V51.9527H198.888V16.5535V16.6127Z" fill="white" />
                              <path d="M189.451 34.2513V21.4058C189.451 18.9787 189.628 15.2494 188.448 12.9407C187.445 10.9281 185.322 8.97461 181.783 8.97461C178.244 8.97461 176.12 10.9281 175.118 12.9407C173.938 15.2494 174.115 18.9787 174.115 21.4058V34.3105V47.1561C174.115 49.5831 173.938 53.3125 175.118 55.6211C176.297 57.9297 178.244 59.5872 181.783 59.5872C185.322 59.5872 187.445 57.6338 188.448 55.6211C189.628 53.3125 189.451 49.5831 189.451 47.1561V34.2513ZM182.609 34.2513V51.9509H181.783H180.957V34.2513V16.5517H181.783H182.609V34.2513Z" fill="white" />
                              <path d="M132.786 0.692579C132.477 5.24012 128.516 8.70718 123.985 8.39716C124.294 3.84962 128.254 0.382559 132.786 0.692579Z" fill="#29D300" />
                              <path d="M115.744 0.712891C119.628 0.978622 122.589 4.38522 122.324 8.28312C118.44 8.01738 115.479 4.61078 115.744 0.712891Z" fill="#29D300" />
                           </g>
                           <defs>
                              <clipPath id="clip0_117_671">
                                 <rect width="230.865" height="59.1961" fill="white" transform="translate(30.1348 0.628906)" />
                              </clipPath>
                           </defs>
                        </svg>
                     </a>
                     <div className="addres">
                        <p>
                           РОССИЯ, КРАСНОДАРСКИЙ КРАЙ, Г. КРАСНОДАР, <br />
                           ПРОЕЗД МАЙСКИЙ, ДОМ 16/3, ПОМЕЩЕНИЕ 3.
                        </p>
                        <a href="tel:8(800)7377721">8 (800) 73 77 721</a>
                     </div>
                  </div>
               </div>
               <div className="col-xl-3 col-md-3">
                  <div className="right">
                     <a href="https://kata.agency/" className="logo" target='_blank' rel="noopener nofollow noreferrer" title='Сайт Kata.Agency'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="116" height="39" viewBox="0 0 116 39" fill="none">
                           <g clipPath="url(#clip0_117_665)">
                              <path d="M69.4119 11.9768L65.1229 28.9858C64.7285 30.5458 65.1722 32.2568 66.3554 33.3639L71.0388 37.8929C73.0107 39.8555 75.9687 39 76.6588 36.2826L82.4268 13.2852C82.9691 11.4735 84.0044 10.6181 85.7298 10.6181H87.2581C90.0188 10.6181 91.2513 7.54839 89.2793 5.53548L85.6312 1.91226C84.3494 0.603871 82.9691 0 81.2436 0H68.7217C65.6159 0 64.2848 3.37161 66.5033 5.63613L68.5245 7.64903C69.4119 8.55484 70.0528 9.76258 69.4119 11.9265V11.9768Z" fill="white" />
                              <path d="M101.012 1.86122L85.7791 27.6767C84.7932 29.3374 85.0397 31.1993 86.3707 32.5077L91.9415 37.8419C93.519 39.4522 95.8854 39.1503 97.1179 37.1877L100.076 32.1051C100.618 31.1993 101.505 30.6961 102.59 30.6961H105.203C105.893 30.6961 106.633 30.998 107.224 31.5012L110.922 35.1245C113.239 37.2883 116.295 36.0303 115.999 32.9606L114.027 10.869C113.978 9.76187 113.386 8.50381 112.598 7.74897L106.09 1.25735C104.315 -0.554259 102.393 -0.352969 101.062 1.91155L101.012 1.86122Z" fill="white" />
                              <path d="M45.157 1.86123L29.9237 27.6767C28.9377 29.287 29.1842 31.149 30.5153 32.5077L36.086 37.8419C37.6636 39.4522 40.0299 39.1503 41.2624 37.1877L44.2203 32.1051C44.8119 31.1993 45.7486 30.6961 46.7346 30.6961H49.3474C50.1362 30.6961 50.7771 30.9477 51.3686 31.5012L55.1153 35.1245C57.4324 37.4896 60.4889 36.1812 60.1438 32.9606L58.2212 10.869C58.1226 9.76187 57.531 8.50381 56.6929 7.74897L50.1855 1.25736C48.6079 -0.352965 46.2909 -0.0510296 45.157 1.91155V1.86123Z" fill="white" />
                              <path d="M23.91 35.6293C24.8467 34.8241 24.9946 33.2641 24.3044 31.7041L21.2972 24.9106C20.7056 23.7532 21.0507 22.4448 22.1845 21.4383L32.4387 12.2293C34.0162 10.8706 34.0162 8.40479 32.4387 6.84479L26.8186 1.15834C25.4876 -0.250696 23.6142 -0.250696 22.0859 1.15834L16.5645 6.14027C15.9729 6.69382 15.2334 6.69382 14.5925 6.03963L12.2262 3.72479C9.95844 1.56092 6.60613 2.56737 5.86665 5.63705L0.14799 28.0809C-0.2464 29.6409 0.0986917 30.7983 1.18326 31.9054L7.6907 38.1958C8.97247 39.4538 10.8951 38.9003 11.2895 37.1893L12.6699 31.8048C12.9657 30.6977 14.2474 30.3958 15.0362 31.1506L19.7196 35.2267C21.1 36.4848 22.7268 36.5854 23.91 35.6293Z" fill="white" />
                           </g>
                           <defs>
                              <clipPath id="clip0_117_665">
                                 <rect width="116" height="39" fill="white" />
                              </clipPath>
                           </defs>
                        </svg>
                     </a>
                     <div className="develop">
                        <p>Дизайн и разработка:</p>
                        <a href="https://kata.agency/" target='_blank' rel="noopener nofollow noreferrer" title='Сайт Kata.Agency'>Kata.Agency</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </footer>
   );
}

export default Footer;