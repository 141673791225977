import { useRef, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import gsap from 'gsap';
import { useMediaQuery } from 'react-responsive';
import './ModalProduct.scss';
import LazyLoad from 'react-lazyload';
import Footer from '../Footer/Footer';

function ModalProduct({ modalData, setModalData }) {
   const prevBtn = useRef(null);
   const nextBtn = useRef(null);
   const swiperRef = useRef(null);
   const isTablet = useMediaQuery({ query: '(max-width: 1199px)' });
   const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
   const [videoKey, setVideoKey] = useState(Date.now()); // ключ для ререндеринга видео
   const modalBlock = useRef(null)

   const scrollTop = () => {
      setTimeout(() => {
         modalBlock.current.scrollTop = 0
      }, 500);
   }

   const slideList = [
      {
         video: './img/product/Klassika.mp4',
         videoTab: './img/product/Klassika-pad.mp4',
         videoMobile: './img/product/Klassika-mob.mp4',
         title: 'Классика',
         bgColor: 'rgba(4, 159, 188, 1)',
         structure: 'Кокосовая стружка, патока крахмальная, сироп топинамбура (клубни топинамбура, вода).',
         energyValue: [
            {
               protein: '5',
               fats: '29',
               carbohydrates: '52',
               kcal: '490 ккал/ 2040 кДж',
            }
         ]
      },
      {
         video: './img/product/Malina.mp4',
         videoTab: './img/product/Malina-pad.mp4',
         videoMobile: './img/product/Malina-mob.mp4',
         title: 'Малина',
         bgColor: '#FF618F',
         structure: 'кокосовая стружка, патока крахмальная, сироп топинамбура (клубни топинамбура, вода), малина сушеная.',
         energyValue: [
            {
               protein: '3',
               fats: '33',
               carbohydrates: '52',
               kcal: '510 ккал/ 2130 кДж',
            }
         ]
      },
      {
         video: './img/product/Mango.mp4',
         videoTab: './img/product/Mango-pad.mp4',
         videoMobile: './img/product/Mango-mob.mp4',
         title: 'С манго',
         bgColor: '#FF5C00',
         structure: 'кокосовая стружка, патока крахмальная, манго сушеное, сироп топинамбура (клубни топинамбура, вода).',
         energyValue: [
            {
               protein: '3',
               fats: '28',
               carbohydrates: '56',
               kcal: '490 ккал/ 2040 кДж',
            }
         ]
      },
      {
         video: './img/product/Chernika.mp4',
         videoTab: './img/product/Chernika-pad.mp4',
         videoMobile: './img/product/Chernika-mob.mp4',
         title: 'С черникой',
         bgColor: '#BC7DA0',
         structure: 'кокосовая стружка, патока крахмальная, сироп топинамбура (клубни топинамбура, вода), черника сушеная.',
         energyValue: [
            {
               protein: '5',
               fats: '24',
               carbohydrates: '49',
               kcal: '510 ккал/ 2130 кДж',
            }
         ]
      },
   ];

   useEffect(() => {
      if (swiperRef.current) {

         if (modalData) {
            swiperRef.current.slideTo(modalData);
            const bgSide = swiperRef.current.slides[modalData].dataset.bg
            modalBlock.current.style.background = bgSide
            setModalData(null)
         }
         const swiperSlideLength = swiperRef.current.slides.length - 1;
         const prevBtnTitle = prevBtn.current.querySelector('.subtitle');
         const nextBtnTitle = nextBtn.current.querySelector('.subtitle');

         // Инициализация подзаголовка предыдущего и следующего слайда после открытия
         if (!swiperRef.current.isBeginning) {
            const nameSlidePrev = swiperRef.current.slides[swiperRef.current.activeIndex - 1].getAttribute('data-name');
            prevBtnTitle.innerText = nameSlidePrev;

            if (swiperRef.current.activeIndex === swiperSlideLength) {
               const nameSlideNext = swiperRef.current.slides[0].getAttribute('data-name');
               nextBtnTitle.innerText = nameSlideNext;
            } else {
               const nameSlideNext = swiperRef.current.slides[swiperRef.current.activeIndex + 1].getAttribute('data-name');
               nextBtnTitle.innerText = nameSlideNext;
            }

         } else {
            const nameSlidePrev = swiperRef.current.slides[swiperSlideLength].getAttribute('data-name');
            prevBtnTitle.innerText = nameSlidePrev;

            const nameSlideNext = swiperRef.current.slides[swiperRef.current.activeIndex + 1].getAttribute('data-name');
            nextBtnTitle.innerText = nameSlideNext;
         }
      }
   }, [modalData, setModalData]);

   useEffect(() => {
      setVideoKey(Date.now()); // обновляем ключ при изменении разрешения
   }, [isTablet, isMobile]);

   return (
      <div className="modal_product" ref={modalBlock}>
         <div className="container">
            <div className="modal_product__wrap">
               <Swiper
                  className='sw_product'
                  spaceBetween={60}
                  slidesPerView={1}
                  allowTouchMove={false}
                  simulateTouch={false}
                  speed={1}
                  onSwiper={(swiper) => {
                     swiperRef.current = swiper;
                     const swiperSlideLength = swiper.slides.length - 1;
                     const prevBtnTitle = prevBtn.current.querySelector('.subtitle');
                     const nextBtnTitle = nextBtn.current.querySelector('.subtitle');

                     // Изменение подзаголовков предыдущего и следующего слайда при клике на кнопку назад
                     prevBtn.current.addEventListener('click', function () {
                        document.querySelector('.animate_page').classList.add('active');
                        scrollTop()

                        setTimeout(() => {
                           document.querySelector('.animate_page').classList.remove('active');
                        }, 1300);

                        setTimeout(() => {
                           if (!swiper.isBeginning) {
                              const nameSlideNext = swiper.slides[swiper.activeIndex].getAttribute('data-name');
                              nextBtnTitle.innerText = nameSlideNext;

                              if (swiper.activeIndex === 1) {
                                 const nameSlidePrev = swiper.slides[swiperSlideLength].getAttribute('data-name');
                                 prevBtnTitle.innerText = nameSlidePrev;
                              } else {
                                 const nameSlidePrev = swiper.slides[swiper.activeIndex - 2].getAttribute('data-name');
                                 prevBtnTitle.innerText = nameSlidePrev;
                              }

                              swiper.slidePrev(1);
                           } else {
                              const nameSlidePrev = swiper.slides[swiperSlideLength - 1].getAttribute('data-name');
                              prevBtnTitle.innerText = nameSlidePrev;

                              const nameSlideNext = swiper.slides[swiper.activeIndex].getAttribute('data-name');
                              nextBtnTitle.innerText = nameSlideNext;

                              swiper.slideTo(swiperSlideLength, 1);
                           }

                           const bgSlide = swiper.slides[swiper.activeIndex].getAttribute('data-bg');
                           if (bgSlide !== undefined && bgSlide !== '') {
                              gsap.to(document.querySelector('.modal_product'), {
                                 background: bgSlide,
                                 duration: 0,
                                 ease: 'none',
                              });
                           } else {
                              gsap.to(document.querySelector('.modal_product'), {
                                 background: 'red',
                                 duration: 0,
                                 ease: 'none',
                              });
                           }
                        }, 1000);
                     });

                     // Изменение подзаголовков предыдущего и следующего слайда при клике на кнопку вперед
                     nextBtn.current.addEventListener('click', function () {
                        document.querySelector('.animate_page').classList.add('active');
                        scrollTop()

                        setTimeout(() => {
                           document.querySelector('.animate_page').classList.remove('active');
                        }, 1300);

                        setTimeout(() => {
                           if (!swiper.isEnd) {
                              swiper.slideNext(1);

                              const nameSlidePrev = swiper.slides[swiper.activeIndex - 1].getAttribute('data-name');
                              prevBtnTitle.innerText = nameSlidePrev;

                              if (swiper.activeIndex === swiperSlideLength) {
                                 const nameSlideNext = swiper.slides[0].getAttribute('data-name');
                                 nextBtnTitle.innerText = nameSlideNext;
                              } else {
                                 const nameSlideNext = swiper.slides[swiper.activeIndex + 1].getAttribute('data-name');
                                 nextBtnTitle.innerText = nameSlideNext;
                              }
                           } else {
                              const nameSlideNext = swiper.slides[1].getAttribute('data-name');
                              nextBtnTitle.innerText = nameSlideNext;

                              const nameSlidePrev = swiper.slides[swiperSlideLength].getAttribute('data-name');
                              prevBtnTitle.innerText = nameSlidePrev;

                              swiper.slideTo(0, 1);
                           }

                           const bgSlide = swiper.slides[swiper.activeIndex].getAttribute('data-bg');
                           if (bgSlide !== undefined && bgSlide !== '') {
                              gsap.to(document.querySelector('.modal_product'), {
                                 background: bgSlide,
                                 duration: 0,
                                 ease: 'none',
                              });
                           } else {
                              gsap.to(document.querySelector('.modal_product'), {
                                 background: 'red',
                                 duration: 0,
                                 ease: 'none',
                              });
                           }
                        }, 1000);
                     });
                  }}
               >
                  {slideList.map((item, index) => (
                     <SwiperSlide className='item_slide row' data-name={item.title} data-bg={item.bgColor} key={index}>
                        <div className="col-xl-6 col-md-8">
                           <div className="item_slide__left">
                              <LazyLoad height={0}>
                                 <video key={`${item.title}-${videoKey}`} autoPlay loop muted playsInline>
                                    <source src={isMobile ? item.videoMobile : (isTablet ? item.videoTab : item.video)} type="video/mp4" />
                                 </video>
                              </LazyLoad>
                           </div>
                        </div>
                        <div className="offset-xl-1 col-xl-5 col-md-4">
                           <div className="meta_product" style={{ background: isMobile ? item.bgColor : '' }}>
                              <button className='meta_product__close close-modal anim_page_btn' type='button' onClick={scrollTop()}>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                                    <rect width="42" height="42" rx="21" fill="white" fillOpacity="0.2" />
                                    <path d="M14 21H28M14 21L18.6667 16M14 21L18.6667 26" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                 </svg>
                                 <span>назад</span>
                              </button>
                              <h2 className='meta_product__title'>{item.title}</h2>
                              <div className="meta_product__descr">
                                 <div className="title">Состав:</div>
                                 <div className="text">
                                    <p>
                                       {item.structure}
                                    </p>
                                 </div>
                              </div>
                              <div className="energy_value">
                                 <div className="energy_value__title">Пищевая ценность продукта:</div>
                                 <div className="energy_list">
                                    <div className="energy_list__item">
                                       белки: {item.energyValue[0].protein}
                                    </div>
                                    <div className="energy_list__item">
                                       жиры: {item.energyValue[0].fats}
                                    </div>
                                    <div className="energy_list__item">
                                       углеводы: {item.energyValue[0].carbohydrates}
                                    </div>
                                    <div className="energy_list__item">
                                       ккал: {item.energyValue[0].kcal}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </SwiperSlide>
                  ))}
                  <div className="sw_buttons">
                     <button type='button' className="sw_btn swiper-button-prev sw_product_prev" ref={prevBtn}>
                        <div className="icon">
                           <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                              <rect width="42" height="42" rx="21" fill="white" fillOpacity="0.2" />
                              <path d="M14 21H28M14 21L18.6667 16M14 21L18.6667 26" stroke="white" strokeWidth="2" strokeLinecap="round" />
                           </svg>
                        </div>
                        <div className="subtitle"></div>
                     </button>
                     <button type='button' className="sw_btn swiper-button-next sw_product_next" ref={nextBtn}>
                        <div className="subtitle"></div>
                        <div className="icon">
                           <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                              <rect x="42" y="42" width="42" height="42" rx="21" transform="rotate(-180 42 42)" fill="white" fillOpacity="0.2" />
                              <path d="M28 21L14 21M28 21L23.3333 26M28 21L23.3333 16" stroke="white" strokeWidth="2" strokeLinecap="round" />
                           </svg>
                        </div>
                     </button>
                  </div>
               </Swiper>
            </div>
         </div>
         {isTablet && !isMobile ? (
            <Footer />
         ) : ''}
      </div>
   );
}

export default ModalProduct;
