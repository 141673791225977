import { useEffect, useRef, useState } from 'react'
import './FrontHead.scss'
import FrontCatalog from '../FrontCatalog/FrontCatalog';
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useMediaQuery } from 'react-responsive'
import { CheckWebp } from '../../../utilities/CheckWebp'

gsap.registerPlugin(ScrollTrigger);

function FrontHead({ setModalData }) {
   const [webPSupported, setWebPSupported] = useState(true);
   const isTablet = useMediaQuery({ query: '(max-width: 1199px)' })
   const mainBlockRef = useRef(null)
   const bgRef = useRef(null)
   const heartsRef = useRef(null)
   const scrollTriggerRefs = useRef([])

   const HeaertaAnimate = () => {
      const heartsArray = gsap.utils.toArray(heartsRef.current.children)
      const heartsAnimation = gsap.to(heartsArray, {
         x: 0,
         y: 0,
         ease: 'none',
         scrollTrigger: {
            trigger: mainBlockRef.current,
            start: "top top",
            end: "bottom bottom+=50%",
            scrub: true,
         }
      })

      const bgAnimation = gsap.to(bgRef.current, {
         filter: 'blur(80px)',
         ease: 'none',
         scrollTrigger: {
            trigger: mainBlockRef.current,
            start: "top top-=200",
            end: "bottom bottom+=50%",
            scrub: true,
         }
      })

      gsap.set(bgRef.current, { filter: 'blur(0px)' })

      scrollTriggerRefs.current.push(heartsAnimation.scrollTrigger, bgAnimation.scrollTrigger)
   }

   useEffect(() => {
      if (!isTablet) {
         HeaertaAnimate()
      } else {
         scrollTriggerRefs.current.forEach(trigger => trigger && trigger.kill())
         scrollTriggerRefs.current = []
         gsap.set(bgRef.current, { clearProps: 'all' })
      }

      return () => {
         scrollTriggerRefs.current.forEach(trigger => trigger && trigger.kill())
         scrollTriggerRefs.current = []
      }
   }, [isTablet])

   return (
      <div className='front_head_main' ref={mainBlockRef} id='top'>
         <section className='front_head' ref={bgRef}>
            <div className="container">
               <div className="front_wrap">
                  <h1>
                     <b>Натурально</b> вкусные <br />
                     конфеты Coconessa
                  </h1>
                  <div className="top_number">
                     <a href="tel:+7(800)737-77-21">+7 (800) 737-77-21</a>
                  </div>
               </div>
            </div>
            <div className="front_head__bg">
               <picture>
                  <source width="100%" height="100%" srcSet={CheckWebp('./img/first-mobile.jpg', webPSupported, setWebPSupported)} media="(max-width: 500px)" alt="Конфеты coconessa" />
                  <img width="100%" height="100%" src={CheckWebp('./img/first.jpg', webPSupported, setWebPSupported)} alt="Конфеты coconessa" />
               </picture>
            </div>
            {!isTablet ? (
               <div className="front_head__hearts">
                  <div className="heart_item">
                     <svg ref={heartsRef} width="1360" height="1300" viewBox="0 0 1360 1300" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g className='heart1'>
                           <path d="M355.471 944.145L650 650.036L355.473 355.93C314.844 315.359 261.599 295 208.21 295C154.818 295 101.721 315.213 60.9449 355.932C-20.3149 437.074 -20.3149 568.895 60.9441 650.038C-20.3141 731.18 -20.3141 863.001 60.9457 944.143C142.205 1025.29 274.214 1025.29 355.471 944.145Z" fill="white" fillOpacity="0.7" />
                        </g>
                        <g className='heart2'>
                           <path d="M1004.53 355.855L710 649.964L1004.53 944.07C1045.16 984.641 1098.4 1005 1151.79 1005C1205.18 1005 1258.28 984.787 1299.06 944.068C1380.31 862.926 1380.31 731.105 1299.06 649.962C1380.31 568.82 1380.31 436.999 1299.05 355.857C1217.8 274.715 1085.79 274.715 1004.53 355.855Z" fill="#FF739F" />
                        </g>
                        <g className='heart3'>
                           <path d="M974.075 944.529L679.966 650L385.861 944.527C345.29 985.156 324.93 1038.4 324.93 1091.79C324.93 1145.18 345.143 1198.28 385.862 1239.06C467.004 1320.31 598.826 1320.31 679.968 1239.06C761.11 1320.31 892.931 1320.31 974.073 1239.05C1055.22 1157.8 1055.22 1025.79 974.075 944.529Z" fill="#E88BFE" />
                        </g>
                        <g className='heart4'>
                           <path d="M385.855 355.471L679.964 650L974.07 355.473C1014.64 314.844 1035 261.599 1035 208.21C1035 154.818 1014.79 101.721 974.068 60.9449C892.926 -20.3148 761.105 -20.3148 679.962 60.9442C598.82 -20.3141 466.999 -20.3141 385.857 60.9457C304.715 142.205 304.715 274.214 385.855 355.471Z" fill="#EDB72D" />
                        </g>
                     </svg>
                  </div>
               </div>
            ) : (<></>)}
         </section>
         <FrontCatalog setModalData={setModalData} />
      </div>
   );
}

export default FrontHead;


