import { useEffect, useState, useCallback } from 'react';
import './FrontCatalog.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Squircle } from '@squircle-js/react';
import { useMediaQuery } from 'react-responsive';
import ReactPlayer from 'react-player';

function FrontCatalog({ setModalData }) {
   const [hoveredIndex, setHoveredIndex] = useState(null);
   const isMac = useMediaQuery({ query: '(min-width: 2000px)' });
   const isDesktop = useMediaQuery({ query: '(max-width: 1999px)' });
   const isTablet = useMediaQuery({ query: '(max-width: 1199px)' });

   const listCatalog = [
      {
         image: './img/catalog/classic.png',
         alt: 'Классика',
         title: 'Классика',
         imageHover: './img/product/Klassika-mob.mp4',
         bgColor: 'rgba(4, 159, 188, 1)'
      },
      {
         image: './img/catalog/raspberry.png',
         alt: 'Малина',
         title: 'Малина',
         imageHover: './img/product/Malina-mob.mp4',
         bgColor: '#FF618F'
      },
      {
         image: './img/catalog/mango.png',
         alt: 'Манго',
         title: 'Манго',
         imageHover: './img/product/Mango-mob.mp4',
         bgColor: '#FF5C00'
      },
      {
         image: './img/catalog/blueberries.png',
         alt: 'Черника',
         title: 'Черника',
         imageHover: './img/product/Chernika-mob.mp4',
         bgColor: '#BC7DA0'
      },
   ];

   const openModal = useCallback((block, item) => {
      const thisSlideId = item.dataset.slide;
      setModalData(thisSlideId);
      setTimeout(() => {
         block.classList.add('active');
      }, 800);
   }, [setModalData]);

   const closeModal = useCallback((block) => {
      setTimeout(() => {
         block.classList.remove('active');
      }, 800);
   }, []);

   useEffect(() => {
      const modalBlock = document.querySelector('.modal_product');
      const allBtn = document.querySelectorAll('.front_catalog .item .open_product');
      const allBtnClose = document.querySelectorAll('.close-modal');

      allBtn.forEach(item => {
         item.addEventListener('click', function () {
            openModal(modalBlock, item);
         });
      });

      allBtnClose.forEach(item => {
         item.addEventListener('click', function () {
            closeModal(modalBlock);
         });
      });

      return () => {
         allBtn.forEach(item => {
            item.removeEventListener('click', function () {
               openModal(modalBlock, item);
            });
         });

         allBtnClose.forEach(item => {
            item.removeEventListener('click', function () {
               closeModal(modalBlock);
            });
         });
      };
   }, [openModal, closeModal]);

   return (
      <section className='front_catalog' id={isMac ? 'catalog' : (isTablet ? 'catalog' : '')}>
         <div className="container">
            <div className="title">
               Самый <b>короткий состав</b> и самое длинное послевкусие
            </div>
            <Swiper className='sw_catalog'
               spaceBetween={20}
               slidesPerView={4}
               id={isDesktop && !isTablet ? 'catalog' : ''}
               autoHeight={true}
               breakpoints={{
                  1: {
                     slidesPerView: 'auto',
                     spaceBetween: 7
                  },
                  768: {
                     slidesPerView: 3,
                     spaceBetween: 10
                  },
                  1200: {
                     slidesPerView: 4,
                     spaceBetween: 20
                  }
               }}
            >
               {listCatalog.map((item, index) => (
                  <SwiperSlide className='sw_cat_slide' key={index}>
                     <Squircle
                        cornerRadius={50}
                        cornerSmoothing={1}
                        className='item'
                        style={{ background: item.bgColor }}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                     >
                        <ReactPlayer
                           className="image"
                           width=""
                           height=""
                           url={item.imageHover}
                           playing={isTablet ? false : hoveredIndex === index}
                           loop={true}
                           muted={true}
                           volume={0}
                           playsinline={isTablet ? false : hoveredIndex === index}
                        />
                        <div className="title_prod">{item.title}</div>
                        <button className="open_product anim_page_btn" type='button' data-slide={index} aria-label={`Открыть конфеты ${item.title}`}></button>
                     </Squircle>
                  </SwiperSlide>
               ))}
            </Swiper>
         </div>
         <div className="hearts_catalog">
            <svg width="644" height="609" viewBox="0 0 644 609" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M182.631 166.333L321.838 304.15L461.043 166.334C480.246 147.322 489.882 122.408 489.882 97.4263C489.882 72.4429 480.315 47.5976 461.042 28.5175C422.636 -9.50577 360.243 -9.50577 321.837 28.5171C283.431 -9.50542 221.038 -9.50542 182.632 28.5178C144.226 66.5408 144.226 128.311 182.631 166.333Z" fill="#EDB72D" />
               <path d="M168.25 441.787L307.656 304.167L168.251 166.548C149.021 147.564 123.819 138.037 98.5493 138.037C73.278 138.037 48.1463 147.496 28.8462 166.549C-9.61536 204.517 -9.61535 266.199 28.8459 304.167C-9.61499 342.136 -9.61499 403.818 28.8466 441.786C67.3078 479.754 129.79 479.754 168.25 441.787Z" fill="white" fillOpacity="0.7" />
               <path d="M475.46 162.905L336.054 302.111L475.459 441.316C494.689 460.519 519.891 470.156 545.161 470.156C570.432 470.156 595.564 460.588 614.864 441.316C653.325 402.91 653.325 340.516 614.864 302.111C653.325 263.705 653.325 201.311 614.863 162.906C576.402 124.5 513.92 124.5 475.46 162.905Z" fill="#FF739F" />
               <path d="M461.079 441.966L321.872 304.149L182.667 441.965C163.464 460.976 153.828 485.891 153.828 510.872C153.828 535.856 163.395 560.701 182.668 579.781C221.074 617.805 283.467 617.805 321.873 579.782C360.279 617.804 422.672 617.804 461.078 579.781C499.484 541.758 499.484 479.988 461.079 441.966Z" fill="#E88BFE" />
            </svg>
         </div>
      </section>
   );
}

export default FrontCatalog;
